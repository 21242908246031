import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  /*overflow-x: scroll;*/
`;

const PartnerImage = styled.img`

`

const Partners = ({partners, style}) => (
  <Container style={{...style}}>
    {partners.map((p, i) => {
      return (
        <PartnerImage 
          key={`partner_${i}`}
          src={p.image}
          style={{marginRight: 16, height: 100, borderRadius: 4}}/>
      )
    })}
  </Container>
);

export default Partners;
