import React from "react"
import { Link } from "gatsby"
import Image from "../components/image"
import Code from '../components/code';
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled, {keyframes} from 'styled-components';
import Button from '../components/button';
import RebaseValue from '../components/rebasevalue';
import RebaseValue2 from '../components/rebasevalue2';
import Pricing from '../components/pricing';
import Aggregate from '../components/aggregate';
import LineChart from '../components/linechart';
import Doughnut from '../components/doughnut';
import { MdCheckCircle } from "react-icons/md";
import {GoHeart} from 'react-icons/go';
import BarChart from '../components/barchart';
import CustomerGrid from '../components/customergrid';
import Triangle from '../components/triangle';
import Partners from '../components/partners';
import CodeSection from './platform/codesection';
import ImproveSection from './platform/improvesection';
import InfraSection from './platform/infrasection';
import Section from '../components/section';






const Row = styled.div`
  display: flex;
  flex-direction: row;
`;




const Col = styled.div`
  display: flex;
  flex-direction: column;
`;






class PlatformPage extends React.Component {


  render() {
    const data = (Array.apply(null, new Array(50))).map((_, i) => {
      return ({
        x: i,
        y: Math.random()*20+Math.random()*-20
      })

    });

    return (
      <Layout {...this.props}>
        <SEO title="Home" />
        {/*<Section>
          <Col style={{position: 'relative', width: '100%'}}>
            <h1 style={{textAlign: 'center', marginTop: 128, fontSize: 48}}>
              World’s first open-source energy forecasting and<br/> management toolkit for energy innovators
            </h1>
          </Col>
        </Section>*/}
        <Section style={{marginTop: 128}}>
          <div>
            <h1 style={{}}><span>Create production-ready</span><br/>
            <span style={{color: '#C9DDFF'}}>Energy AI-forecasts</span>
            <span style={{marginLeft: 8}}>in minutes.</span>
          </h1>
          </div>
          <Row style={{marginTop: 48}}>
            <Col style={{flex: 1, marginRight: 32}}>
              <p style={{fontSize: 18, fontWeight: 400}}>
                Focus on what brings value to you <span style={{fontWeight: 900}}>- creating accurate energy forecasts.</span>
              </p>
              <p style={{fontSize: 18, marginTop: 16, fontWeight: 400}}>
                Our platform takes care of the rest.
              </p>
              <Row style={{alignItems: 'center'}}>
                <MdCheckCircle size={20} color='#C9DDFF'/><div style={{marginLeft: 8, fontSize: 16}}>Weather datasets</div>
              </Row>
              <Row style={{marginTop: 8, alignItems: 'center'}}>
                <MdCheckCircle size={20} color='#C9DDFF'/><div style={{marginLeft: 8, fontSize: 16}}>SOTA open-source ML models</div>
              </Row>
              <Row style={{alignItems: 'center', marginTop: 8}}>
                <MdCheckCircle size={20} color='#C9DDFF'/><div style={{marginLeft: 8, fontSize: 16}}>Data pipelining</div>
              </Row>
              <Row style={{alignItems: 'center', marginTop: 8}}>
                <MdCheckCircle size={20} color='#C9DDFF'/><div style={{marginLeft: 8, fontSize: 16}}>Backtesting</div>
              </Row>
              <Row style={{alignItems: 'center', marginTop: 8}}>
                <MdCheckCircle size={20} color='#C9DDFF'/><div style={{marginLeft: 8, fontSize: 16}}>Deployment</div>
              </Row>
              <Row style={{marginTop: 32}}>
                <Button
                  to='https://dashboard.rebase.energy/auth/signup/trial'
                  text='START FOR FREE'/>
                <Button
                  style={{marginLeft: 16, borderColor: '#c9ffdd', backgroundColor: '#c9ffdd11', color: '#c9ffdd'}}
                  to='https://demomeeting.pipedrive.com/scheduler/ZA3X1mUb/demo-meeting'
                  text='BOOK A DEMO'
                />
              </Row>

            </Col>
            {/*<div style={{flex: 2}}>
              <img src={require('../images/partners/rebase_platform.png')} style={{width: '100%'}}/>
            </div>*/}

            {<Image path='rebaste_platform.png' style={{flex: 2, borderRadius: 4,  boxShadow: '0 2px 8px #121212'}}/>}
          </Row>
        </Section>
        <CodeSection
          ref={r => this.codeSectionRef = r}
        />
        <InfraSection/>
        <Section style={{marginTop: 256, minHeight: null, padding: 0}}>
          <Row style={{marginRight: 48}}>
            <div style={{flex: 3}}/>
            <Col style={{flex: 3}}>
              <h1>
                Aggregate forecasts. <br/><span style={{color: '#C9DDFF'}}>Gain 5-15% accuracy.</span>
              </h1>
              <p style={{marginRight: 92}}>
                Use our smart aggregation layer to aggregate your own forecasts or third-party forecasts, and improve accuracy by up to 5-15%.
              </p>
            </Col>
          </Row>
          <div style={{overflow: 'hidden'}}>
            <LineChart
              style={{width: '120%', height: 400, transform: 'rotate(-6.3deg) translateX(-6%)', pointerEvents: 'none'}}
              datasets={[{
                data: data,
                label: '',
                pointHoverRadius: 1,
                borderColor: '#C9DDFF',
                pointRadius: 0,
                backgroundColor: '#C9DDFF11',
              },
              {
                data: data.map(d => {return {x: d.x, y: d.y-Math.random()*20+Math.random()*20}}),
                label: '',
                pointHoverRadius: 1,
                borderColor: '#C9DDFF',
                pointRadius: 0,
                backgroundColor: '#C9DDFF11',
              },
              /*{
                data: data.map(d => {return {x: d.x, y: d.y-Math.random()*20+Math.random()*20}}),
                label: '',
                pointHoverRadius: 1,
                borderColor: '#C9DDFF',
                pointRadius: 0,
                backgroundColor: '#C9DDFF11',
              }*/
            ]}
            />
          </div>


        </Section>
        <div style={{position: 'relative', width: '100%', overflow: 'hidden', backgroundColor: '#C9DDFF00'}}>
          <Triangle direction='bottomRight' color='#C9DDFF11'/>
        </div>
        <div style={{padding: 48, paddingBottom: 96, backgroundColor: '#C9DDFF11', borderTopStyle: 'solid', borderBottomStyle: 'solid', borderWidth: 4, borderColor: '#18181800'}}>
          <h3 style={{marginTop: 48, color: '#C9DDFF'}}>
            Built for energy <br/>market professionals.
            <br/>
          </h3>
          <h1>
            Who are you?
          </h1>

          <CustomerGrid
            style={{minHeight: 300, marginTop: 48}}
            items={[
              {
                title: 'Energy Trader',
                description: <>Competitiveness in energy markets is increasing with shorter trading horizons and increased volatility from weather-driven renewable production. <br/><br/>Our weather API and open source SDK allows energy traders to develop analytics and machine learning applications such as advance forecasting and algorithmic trading.</>
              },
              {
                title: 'Distribution System Operator',
                description: <>Integration of renewable energy and other distributed energy resources means increase complexity for DSOs to manage the grid stability. <br/><br/> Our API allows to develop weather and power forecasts that puts you in control, at all times. Our forecast pipeline is ready for Redispatch 2.0.</>
              },
              {
                title: 'District Heating Operator',
                description: <>District heating companies are facing challenges with more stringent target concerning efficiency and emissions. <br/><br/>Through weather analytics applications, operations can be streamlined and oil peaker plants can be avoider through peak shaving.</>
              },
              {
                title: 'Power Producer',
                description: <>In order to maintain margins as power prices fall, power producers needs to go beyond preventive based maintenance scheduling. <br/><br/> Predictive and opportunistic maintenance takes in to account both weather condition and energy prices to optimise operations and profits.</>
              },
              {
                title: 'Aggregator',
                description: <>Aggregators can leverage our API and open source SDK to develop proprietary optimisation algorithms for distributed energy resources such as renewables, buildings, batteries, smart thermostats and more. All in 80% less time compared to from-scratch-development.</>
              },
              {
                title: 'Service Provider',
                description: <>We empower service providers like building energy management systems, virtual power plants, grid analytics platforms and asset management platforms with value-creating plug-in components.</>
              }
            ]}
          />
        </div>
        <div style={{width: '100%', overflow: 'hidden', backgroundColor: '#C9DDFF00'}}>
          <Triangle direction='topLeft' color='#C9DDFF11'/>
        </div>
        <ImproveSection/>
        <div style={{padding: 48}}>
          <Row style={{alignItems: 'center'}}>
            <h1 style={{padding: 0, margin: 0}}>
              Our partners
            </h1>
          </Row>
          <Partners
            style={{marginTop: 32}}
            partners={[
              {image: require('../images/partners/kth_3.png')},
              {image: require('../images/partners/energy_agency_2.png')},
              {image: require('../images/partners/reach.png')},
              {image: require('../images/partners/edi_2.png')},
              {image: require('../images/partners/ai_sweden_2.png')},
              {image: require('../images/partners/iea_2.png')},
              {image: require('../images/partners/esa_2.png')},
              {image: require('../images/partners/sting_2.png')},
              {image: require('../images/partners/rise_2.png')},
              {image: require('../images/partners/synerleap_2.png')},
              {image: require('../images/partners/vinnova_2.png')},
              {image: require('../images/partners/fiware_2.png')},
              {image: require('../images/partners/ai4cities.png')},
            ]}
          />
        </div>
        <div style={{
          alignItems: 'center',
          backgroundColor: '#C9DDFF11', padding: 48, marginTop: 64}}>
          <Col style={{alignItems: 'flex-start'}}>
            <h2>
              Ready to get started?
            </h2>
            <div style={{flex: 1}}/>
            <Button
              text='START FOR FREE'
              to='https://dashboard.rebase.energy/auth/signup/trial'/>
          </Col>


        </div>

        {/*<Section style={{alignItems: 'center', marginTop: 128}}>
          <h1 style={{textAlign: 'center'}}>
            Start for <span style={{color: '#C9DDFF'}}>free.</span><br/> Pay for what you <span style={{color: '#C9DDFF'}}>use.</span>
          </h1>
          <Pricing
            style={{marginTop: 32}}
            prices={[
              {
                title: 'Free',
                price: '$0',
                text: '1 site',
                buttonText: 'START NOW'
              },
              {
                title: 'Premium',
                price: '$100/site',
                text: '',
                buttonText: 'START NOW'
              }
            ]}
          />
        </Section>*/}
      </Layout>
    );
  }

}

export default PlatformPage;
