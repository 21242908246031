import React, { Component } from 'react';
import styled, {keyframes} from 'styled-components';
import RebaseValue2 from '../../components/rebasevalue2';
import Section from '../../components/section';






const Row = styled.div`
  display: flex;
  flex-direction: row;
`;




const Col = styled.div`
  display: flex;
  flex-direction: column;
`;


const InnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

class InfraSection extends Component {

  render() {
    return (
      <Section style={{alignItems: 'center', marginTop: 256}}>
        <InnerContainer style={{alignItems: 'stretch', alignItems: 'center', width: '100%', marginTop: 32}}>
          <Col style={{flex: 1}}>
            <h1>
              Spend <span style={{color: '#C9DDFF'}}>zero</span> time on infrastructure.
            </h1>
            <p style={{fontSize: 18}}>

              <span style={{marginLeft: 0}}>
                  We provide you the tools, data and infrastructure needed so that you can focus on creating the best machine learning models.
              </span>
            </p>
          </Col>

          {<Col style={{flex: 1, marginLeft: 32}}>
            <RebaseValue2/>
          </Col>}

        </InnerContainer>
      </Section>

    );
  }

}

export default InfraSection;
