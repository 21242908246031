import React, { Component } from 'react';
import styled, {keyframes} from 'styled-components';
import {MdCode, MdCloud} from 'react-icons/md';

const Square = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  border-radius: 4px;
  background: #C9DDFF11; /*#ff8e8e*/
  border-color: #C9DDFF00;
  border-style: solid;
  border-width: 1px;
  color: #C9DDFF;
  height: ${props => props.size+'px'};
  width: ${props => props.size+'px'};
  font-size: 11px;
  text-align: center;
  letter-spacing: 0.5px;
  font-weight: bold;
  box-shadow: 0 0 4 4px #181818;
`;

const PulseAnim = keyframes`
    0% {
      box-shadow: 0 0 0 0 rgba(230, 239, 255, 0.6);
    }
    70% {
        box-shadow: 0 0 0 30px rgba(230, 239, 255, 0);
    }
    100% {
        box-shadow: 0 0 0 0 rgba(230, 239, 255, 0);
    }
  }
`



const Circle = styled(Square)`
  position: relative;
  background-color: #C9DDFF;
  color: #C9DDFF;
  border-radius: ${props => props.size+'px'};
  border-color: transparent;
  color: #424242;
  animation-name: '${PulseAnim}';
  animation-duration: 2.5s;
  animation-iteration-count: infinite;
  box-shadow: 0 0 0 rgba(230, 239, 255, 0.6);
`;


const Col = styled.div`
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;



class RebaseValue extends Component {

  state = {
    layerPosition: [-16, 0, 16]
  }

  componentDidMount = () => {
    setTimeout(() => {
      this.setState({layerPosition: [-64, 0, 64]})
    }, 5000);
  }

  render() {
    return (
      <Col style={{alignItems: 'center'}}>
        <Row style={{width: '100%'}}>
          <Col style={{alignItems: 'flex-start', flex: 3}}>
            <Col style={{background: '#C9DDFF11',
              borderRadius: 12, padding: 48}}>
              <Row style={{alignItems: 'center'}}>
                <MdCloud color='#C9DDFF' size={24}/>
                <div style={{marginLeft: 16}}>
                  REBASE COMPUTE INFRASTRUCTURE
                </div>
              </Row>
              <Row style={{marginTop: 24, justifyContent: 'center'}}>
                <Square size={120}>
                  Historical weather
                </Square>
                <Square size={120} style={{marginLeft: 24}}>
                  Model training
                </Square>
                <Square size={120} style={{marginLeft: 24}}>
                  Backtesting
                </Square>
              </Row>
              <Col style={{alignItems: 'center', marginTop: 24, }}>
                <div style={{fontSize: 14}}>
                  You can focus on creating the best models
                </div>
                <div style={{width: 2, height: 24, borderLeftStyle: 'dotted', borderColor: '#C9DDFF', borderLeftWidth: 2}}>
                </div>
              </Col>
              <Row style={{marginTop: 0, justifyContent: 'center', marginTop: 4}}>
                <Square size={120}>
                  Hyperparam. optimization
                </Square>
                <Col style={{marginLeft: 24, alignItems: 'center'}}>

                  <Circle size={120}>
                    <Col style={{alignItems: 'center'}}>
                      <MdCode size={24}/>
                      <div>
                        ML CODE
                      </div>
                    </Col>
                  </Circle>
                </Col>

                <Square size={120} style={{marginLeft: 24}}>
                  Serving API
                </Square>
              </Row>
              <Row style={{marginTop: 48, justifyContent: 'center'}}>
                <Square size={120}>
                  Deployment
                </Square>
                <Square size={120} style={{marginLeft: 24}}>
                  Performance monitoring
                </Square>
                <Square size={120} style={{marginLeft: 24}}>
                  Forecast generation
                </Square>

              </Row>

            </Col>
          </Col>
        </Row>
        <Row style={{marginTop: 64, justifyContent: 'center', alignItems: 'center', width: '100%'}}>

          <Col style={{flex: 3, alignItems: 'center'}}>

          </Col>

        </Row>
      </Col>
    );
  }

}

export default RebaseValue;
