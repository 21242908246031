import React from 'react';
import {Line} from 'react-chartjs-2';

const LineChart = ({datasets, style, options}) => {
  options = options === undefined ? {} : options;
  let formattedDatasets = [];
  if (datasets){
    formattedDatasets = datasets.map(d => {
      return ({
          borderWidth: 1.5,
          lineTension: 0,
          ...d
      })
    });
  }

  return (
    <div style={{position: 'relative', ...style}}>
    <Line
      data={{
          datasets: formattedDatasets
      }}
      options={{
        responsive: true,
        maintainAspectRatio: false,
        hover: {
            animationDuration: 0,
            mode: 'nearest',
            intersect: false
        },
        legend: {
            labels: {
                fontColor: "#ffffff00",
            },
            display: false
        },
        scales: {
            yAxes: [{
              gridLines: {
                color: '#C9DDFF00',
                zeroLineColor: '#C9DDFF00',
              },
              ticks: {
                  suggestedMin: 0,
                  suggestedMax: options.suggestedMax ? options.suggestedMax : 0,
                  fontColor: '#ffffff00',
                  autoSkip: true,
                  maxTicksLimit: 5
              }
            }],
            xAxes: [{
              gridLines: {
                color: '#C9DDFF00',
                zeroLineColor: '#C9DDFF00',
              },
              type: 'time',
              ticks: {
                fontColor: '#ffffffe9',
                autoSkip: true,
                maxTicksLimit: 6,
                maxRotation: 0
              },
              time: {
                tooltipFormat:'MMM DD HH:mm',
                unit: 'day',
                unitStepSize: 1,
                displayFormats: {
                   'day': 'MMM DD'
                }
              }
            }]
      },
      tooltips: {
        backgroundColor: '#565656',
        mode: 'x-axis'

      }
    }}
    />
  </div>
  )
}

export default LineChart;
