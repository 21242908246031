import React, { Component } from 'react';
import styled from 'styled-components';

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const Col = styled.div`
  display: flex;
  flex-direction: column;
`


const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: 12px;
  @media (max-width: 900px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;

  }
`;


const Item = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  background-color: ${props => props.selected ? '#C9DDFF33' : '#C9DDFF22'};
  color: ${props => props.selected ? '#C9DDFF' : 'inherit'};
  border-color: ${props => props.selected ? '#C9DDFF' : 'transparent'};
  border-style: solid;
  border-width: 1px;
  text-align: center;
  cursor: ${props => props.selected ? 'default' : 'pointer'};
  transition-duration: 0.2s;
  border-radius: 4px;
  transform: ${props => props.selected ? 'scale(1)' : null};
  user-select: none;
  &:hover {
    transform: ${props => props.selected ? null : 'scale(0.96)'};
    color: #C9DDFF;
    background-color: #C9DDFF33;
    box-shadow: none;
  }
`;

const Title = styled.h4`
  color: inherit;
  padding: 0;
  margin: 0;
`;

const Description = styled.p`
  font-size: 16px;
`;

const DescriptionContainer = styled(Col)`
  background-color: #C9DDFF22;
  padding: 24px;
  border-radius: 4px;
  border-color: #C9DDFF;
  border-style: solid;
  border-width: 1px;
`

class CustomerGrid extends Component {

  state = {
    indexOpen: 0,
  }

  render() {
    return (
      <Row style={{...this.props.style}}>
        <GridContainer style={{flex: 3}}>
          {this.props.items.map((item, i) => {
            return (
              <Item
                key={`customer_${i}`}
                onClick={() => this.setState({indexOpen: i})}
                selected={this.state.indexOpen === i}
                >
                <Title>
                  {item.title}
                </Title>
              </Item>
            )
          })}
        </GridContainer>
        {this.state.indexOpen !== null &&
          <DescriptionContainer style={{flex: 2, marginLeft: 16}}>
            <h3 style={{color: '#C9DDFF'}}>
              {this.props.items[this.state.indexOpen].title}
            </h3>
            <Description style={{marginTop: 0}}>
              {this.props.items[this.state.indexOpen].description}
            </Description>
          </DescriptionContainer>
          ||
          <div style={{flex: 2, marginLeft: 16, padding: 24}}/>
        }
      </Row>
    );
  }

}

export default CustomerGrid;
