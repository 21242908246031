import React, { Component } from 'react';
import styled from 'styled-components';

const Layer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-color: #C9DDFF;
  border-style: solid;
  border-width: 1px;
  height: ${props => `${props.height}px`};
  width: ${props => `${props.width}px`};
  transform: skewX(45deg) rotateX(65deg);
  background-color: #C9DDFF22;
  position: absolute;
  transition-duration: 1s;
`;

const LayerContainer = styled.div`
  position: relative;
`

class Aggregate extends Component {

  state = {
    layerPosition: [-192, -128, -64, 0, 64, 128, 192],
    layerHeight: 80,
    layerWidth: 120,
  }

  constructor(){
    super();

  }

  componentDidMount = () => {
    setTimeout(() => {
      this.setState({
        layerPosition: [-3, -2, -1, 0, 1, 2, 3],
      })
    }, 2500);

    /*setTimeout(() => {
      this.setState({
        layerHeight: 160,
        layerWidth: 240
      });
    }, 3000);*/
  }

  render() {
    return (
      <LayerContainer style={{...this.props.style}}>
        <Layer width={this.state.layerWidth} height={this.state.layerHeight} style={{top: this.state.layerPosition[0]}}/>
        <Layer width={this.state.layerWidth} height={this.state.layerHeight}  style={{top: this.state.layerPosition[1]}}/>
        <Layer width={this.state.layerWidth} height={this.state.layerHeight}  style={{top: this.state.layerPosition[2]}}/>
        <Layer width={this.state.layerWidth} height={this.state.layerHeight}  style={{top: this.state.layerPosition[3]}}/>
        <Layer width={this.state.layerWidth} height={this.state.layerHeight}  style={{top: this.state.layerPosition[4]}}/>
        <Layer width={this.state.layerWidth} height={this.state.layerHeight}  style={{top: this.state.layerPosition[5]}}/>
        <Layer width={this.state.layerWidth} height={this.state.layerHeight}  style={{top: this.state.layerPosition[6]}}/>
      </LayerContainer>
    );
  }

}

export default Aggregate;
