import React, { Component } from 'react';
import styled, {keyframes} from 'styled-components';
import {MdCode, MdCloud} from 'react-icons/md';

const Square = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  border-radius: 4px;
  background: #C9DDFF11; /*#ff8e8e*/
  border-color: #C9DDFF;
  border-style: solid;
  border-width: 2px;
  color: #C9DDFF;
  height: ${props => props.size+'px'};
  width: ${props => props.size+'px'};
  font-size: 11px;
  text-align: center;
  letter-spacing: 1.25px;
  font-weight: bold;
`;

const PulseAnim = keyframes`
    0% {
      box-shadow: 0 0 0 0 rgba(230, 239, 255, 0.6);
    }
    70% {
        box-shadow: 0 0 0 20px rgba(230, 239, 255, 0);
    }
    100% {
        box-shadow: 0 0 0 0 rgba(230, 239, 255, 0);
    }
  }
`



const Circle = styled(Square)`
  position: relative;
  background-color: #C9DDFF;
  color: #C9DDFF;
  border-radius: ${props => props.size+'px'};
  border-color: transparent;
  color: #424242;
  animation-name: '${PulseAnim}';
  animation-duration: 2.5s;
  animation-iteration-count: infinite;
  box-shadow: 0 0 0 rgba(230, 239, 255, 0.6);
`;


const Col = styled.div`
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;



class RebaseValue extends Component {

  state = {
    layerPosition: [-16, 0, 16]
  }

  componentDidMount = () => {
    setTimeout(() => {
      this.setState({layerPosition: [-64, 0, 64]})
    }, 5000);
  }

  render() {
    return (
      <Col style={{alignItems: 'center'}}>
        <Row style={{width: '100%'}}>
          <Col style={{alignItems: 'flex-start', flex: 3}}>
            <p>
              What <span style={{color: '#C9DDFF'}}>REBASE</span> provides
            </p>
            <Col style={{background: '#C9DDFF11',
              borderRadius: 12, padding: 24}}>

              <Row style={{alignItems: 'center'}}>
                <MdCloud color='#C9DDFF' size={24}/>
                <div style={{marginLeft: 16}}>
                  COMPUTE INFRASTRUCTURE
                </div>
              </Row>
              <Row style={{marginTop: 32}}>
                <Square size={120}>
                  HISTORICAL WEATHER
                </Square>
                <Square size={120} style={{marginLeft: 16}}>
                  MODEL TRAINING
                </Square>
                <Square size={120} style={{marginLeft: 16}}>
                  BACKTESTING
                </Square>
                <Square size={120} style={{marginLeft: 16}}>
                  HYPERPARAM. OPTIMIZATION
                </Square>
              </Row>
              <Row style={{marginTop: 32, justifyContent: 'center'}}>
                <Square size={120}>
                  PERFORMANCE MONITORING
                </Square>
                <Square size={120} style={{marginLeft: 16}}>
                  FORECAST GENERATION
                </Square>
                <Square size={120} style={{marginLeft: 16}}>
                  SERVING API
                </Square>
              </Row>

            </Col>
          </Col>
        </Row>
        <Row style={{marginTop: 64, justifyContent: 'center', alignItems: 'center', width: '100%'}}>

          <Col style={{flex: 3, alignItems: 'center'}}>
            <Circle size={100}>
              <Col style={{alignItems: 'center'}}>
                <MdCode size={24}/>
                <div>
                  DEVELOP ML MODEL
                </div>
              </Col>
            </Circle>
          </Col>

        </Row>
      </Col>
    );
  }

}

export default RebaseValue;
