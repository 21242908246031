import React from 'react';
import styled from 'styled-components';

const BaseTriangle = styled.div`
  height: 0;
  width: 0;
  border-style: solid;
`;

const BottomRight = styled(BaseTriangle)`
  border-width: 0 0 10vw 100vw;
  border-color: transparent transparent ${props => props.color} transparent;
`;

const TopLeft = styled(BaseTriangle)`
  border-width: 10vw 100vw 0 0;
  border-color: ${props => props.color} transparent transparent transparent;
`;


const Triangle = ({direction, style, color}) => {
  return (
    <>
    {direction === 'bottomRight' &&
      <BottomRight style={style} color={color}/>
    }
    {direction === 'topLeft' &&
      <TopLeft style={style} color={color}/>
    }
    </>

  );
}

export default Triangle;
